const mutation = {
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
    state.isMobileWindowSize = width > 600 ? false : true;
  },
  UPDATE_USER_INFO(state, user) {
    state.currentUser = user;
  },
  CHANGE_MODE(state, mode) {
    state.darkMode = mode;
  },
  UPDATE_USER_IP_ADDRESS(state, payload) {
    state.userIpAddress = payload;
  },
  RESTORE_DEFAULT_STATE(state) {
    state.currentUser = null;
  },
  UPDATE_MODAL_OPENED(state, modalOpened) {
    state.modalOpened = modalOpened;
  },
  UPDATE_SIDEBAR_MENU(state, moduleList) {
    state.sideBarMenuList = moduleList;
  },
  UPDATE_FORM_ACCESS_RIGHTS(state, accessRights) {
    state.formAccessRights = accessRights;
  },
  UPDATE_FORM_ID_ACCESS_RIGHTS(state, accessRights) {
    state.formIdAccessRights = accessRights;
  },
  //mutation to update whether user is an admin or not
  UPDATE_IS_ADMIN(state, isAdmin) {
    state.isAdmin = isAdmin;
  },
  //mutation to update whether user is a manager or not
  UPDATE_IS_MANAGER(state, isManager) {
    state.isManager = isManager;
  },
  UPDATE_EMP_SEARCH_VAL(state, value) {
    state.empSearchValue = value;
  },
  //mutation to update myteamactivity dashboard
  UPDATE_IS_SEND_EMAIL_BUTTON_CLICKED(state, value) {
    state.isSendEmailButtonClicked = value;
  },
  UPDATE_EMP_FILTER_VAL(state, value) {
    state.empFilterValue = value;
  },
  UPDATE_PAYROLL_MANAGEMENT_URL(state, url) {
    state.payrollUrl = url;
  },
  // update organization details
  UPDATE_ORGANIZATION_DETAILS(state, orgDetails) {
    if (orgDetails && orgDetails.employeeId) {
      localStorage.setItem("LoginEmpId", orgDetails.employeeId);
    }
    state.orgDetails =
      orgDetails && Object.keys(orgDetails).length > 0
        ? orgDetails
        : state.orgDetails;
  },
  // update user details
  UPDATE_USER_DETAILS(state, userDetails) {
    state.userDetails =
      userDetails && Object.keys(userDetails).length > 0
        ? userDetails
        : state.userDetails;
  },

  //mutation to show/hide request access in top notification bar
  SHOW_REQUEST_ACCESS_NOTIFICATION(state, value) {
    state.showRequestAccessNotification = value;
  },

  //mutation to show/hide tax regime selection in top notification bar
  SHOW_TAX_REGIME_SELECTION_OPTION(state, value) {
    state.showTaxRegimeSelection = value;
  },

  //mutation to update new and old tax amount
  UPDATE_TAX_COMPARISON_DETAILS(state, regimeDetails) {
    state.regimeComparisonDetails = regimeDetails;
  },

  //mutation to show/hide new and old tax comparison modal in dashboard
  SHOW_TAX_COMPARISON_MODAL(state, value) {
    state.showTaxComparisonModal = value;
  },

  //mutation to show snackbar to present any error or success messages
  OPEN_SNACKBAR(state, snackbarData) {
    state.showSnackbar = snackbarData.isOpen;
    state.snackBarMessage = snackbarData.message;
    state.snackBarType = snackbarData.type;
    state.snackBarTimeout = snackbarData.timeout;
  },

  //mutation to close snackbar and set it to default values
  CLOSE_SNACKBAR(state) {
    state.showSnackbar = false;
    state.snackBarMessage = "";
    state.snackBarType = "warning";
    state.snackBarTimeout = 5000;
  },

  //to know whether browser Supports Webp or not
  UPDATE_WEBP_SUPPORTS(state, value) {
    state.isWebpSupport = value;
  },

  // update the current tab value in AppTopBar component
  UPDATE_CURRENT_TAB(state, value) {
    state.currentTab = value;
  },

  // set the dashboard type chosen in the plan
  UPDATE_PLAN_DASHBOARD_TYPE(state, value) {
    state.planDashboardType = value;
  },

  // set the subscribed plan status
  UPDATE_SUBSCRIPTION_PLAN_STATUS(state, value) {
    state.planSubscriptionStatus = value;
  },

  // set the trial period when the plan is in trial
  UPDATE_TRIAL_PERIOD(state, value) {
    state.trialPeriod = value;
  },

  UPDATE_AUTO_BILLING_PLAN_CURRENT_STATUS(state, value) {
    state.autoBillingPlanCurrentStatus = value;
  },

  UPDATE_TOPBAR_CLEAR_FLAG(state, flag) {
    state.clearTopBarSearch = flag;
  },

  UPDATE_AUTO_BILLING_FLAG(state, flag) {
    state.isAutoBilling = flag;
  },

  UPDATE_CHARGEBEE_SITE_NAME(state, value) {
    state.chargeBeeSiteName = value;
  },

  UPDATE_AUTO_BILLING_DETAILS(state, value) {
    state.autoBillingDetails = value;
  },

  UPDATE_INTRO_SCREEN_FLAG(state, value) {
    state.isIntroScreenPresented = value;
  },

  UPDATE_COUNTRIES(state, value) {
    state.countryList = value;
  },

  UPDATE_COORDINATES(state, value) {
    state.coordinates = value;
  },

  UPDATE_ENABLE_WORKFLOW(state, payload) {
    state.enableWorkflow = payload;
  },

  UPDATE_VENDOR_FILTER_VAL(state, value) {
    state.vendorAdminFilter = value;
  },

  UPDATE_CUSTOM_FIELDS(state, customFormFields) {
    state.customFormFields =
      customFormFields && Object.keys(customFormFields).length > 0
        ? customFormFields
        : state.customFormFields;
  },
  UPDATE_PROJECT_LABEL(state, label) {
    state.projectLabel = label;
  },
};
export default mutation;
