import gql from "graphql-tag";
// ===============
// Queries
// ===============
export const RETRIEVE_DROPDOWN_DATA = gql`
  query {
    getDropDownBoxDetails {
      errorCode
      message
      fieldForce
      departments {
        Department_Id
        Department_Name
      }
      locations {
        Location_Id
        Location_Name
      }
      workSchedules {
        WorkSchedule_Id
        Title
      }
      employeeType {
        EmpType_Id
        Employee_Type
      }
      serviceProvider {
        Service_Provider_Id
        Service_Provider_Name
      }
      state {
        State_Id
        State_Name
      }
    }
  }
`;
export const LIST_CUSTOM_GROUPS = gql`
  query listCustomEmployeeGroups($formName: String!) {
    listCustomEmployeeGroups(formName: $formName) {
      errorCode
      success
      message
      customGroups {
        Custom_Group_Id
        Custom_Group_Name
      }
    }
  }
`;
export const NO_AUTH_S3_CURD = gql`
  query noAuthS3Crud(
    $fileName: String!
    $action: String!
    $type: String
    $destinationBucket: String
    $destinationFileKey: String
    $data: String
  ) {
    noAuthS3Crud(
      fileName: $fileName
      action: $action
      type: $type
      destinationBucket: $destinationBucket
      destinationFileKey: $destinationFileKey
      data: $data
    ) {
      errorCode
      message
      presignedUrl
      s3DocumentDetails
    }
  }
`;
export const GET_EMPLOYEES_BASED_ON_CUSTOM_GROUP = gql`
  query getEmployeeDetailsBasedOnGroup($customGroupId: [Int]!) {
    getEmployeeDetailsBasedOnGroup(customGroupId: $customGroupId) {
      errorCode
      message
      employeeDetails {
        employeeId
        userDefinedEmpId
        employeeName
        designationName
        departmentName
        groupId
        groupName
      }
    }
  }
`;
export const GET_EMPLOYEE_LIST = gql`
  query getEmployeesDetailsBasedOnRole($formName: String!) {
    getEmployeesDetailsBasedOnRole(formName: $formName) {
      errorCode
      message
      employeeDetails {
        employeeId
        userDefinedEmpId
        employeeName
      }
    }
  }
`;

export const GET_ALL_EMPLOYEES = gql`
  query listAllEmployeeDetails(
    $designationId: [Int]
    $departmentId: [Int]
    $empTypeId: [Int]
    $workScheduleId: [Int]
    $locationId: [Int]
    $formName: String
    $onlyActiveEmployees: Int
  ) {
    listAllEmployeeDetails(
      designationId: $designationId
      departmentId: $departmentId
      empTypeId: $empTypeId
      workScheduleId: $workScheduleId
      locationId: $locationId
      formName: $formName
      onlyActiveEmployees: $onlyActiveEmployees
    ) {
      errorCode
      message
      employeeList {
        employee_id
        emp_status
        user_defined_empid
        employee_name
        emp_email
        designation_name
        department_name
        Designation_Id
        Department_Id
        Location_Id
        EmpType_Id
        Work_Schedule
      }
    }
  }
`;

export const CAMU_CREATE_STAFF = gql`
  query camuCreateStaff($employeeId: [Int]!) {
    camuCreateStaff(employeeId: $employeeId) {
      errorCode
      message
    }
  }
`;

export const GET_CAMU_STATUS = gql`
  query getCamuStatus {
    getCamuStatus {
      errorCode
      message
      camuStatus {
        Employee_Id
        Action
        Log_Timestamp
        Camu_Push_Status
        Failure_Reason
      }
    }
  }
`;

export const CUSTOM_COLOR_PICKER = gql`
  query customColorPicker {
    customColorPicker {
      errorCode
      message
      colorResult {
        Primary_Color
        Secondary_Color
        Hover_Color
        Table_Header_Color
        Table_Header_Text_Color
      }
    }
  }
`;

// ===============
// Mutation
// ===============
export const CHECK_ACCESS_RIGHTS = gql`
  mutation ($formName: String!, $employeeId: Int!) {
    getAccessRights(formName: $formName, employeeId: $employeeId) {
      errorCode
      message
      rights {
        Role_View
        Role_Add
        Role_Update
        Role_Delete
        Role_Optional_Choice
        Role_Hr_Group
        Role_Payroll_Group
        Is_Manager
        Employee_Role
      }
    }
  }
`;

export const GET_PRESIGNED_URL = gql`
  mutation (
    $fileName: String!
    $action: String!
    $type: String
    $destinationBucket: String
    $destinationFileKey: String
    $data: String
  ) {
    getPresignedUrl(
      fileName: $fileName
      action: $action
      type: $type
      destinationBucket: $destinationBucket
      destinationFileKey: $destinationFileKey
      data: $data
    ) {
      errorCode
      message
      presignedUrl
      s3DocumentDetails
    }
  }
`;

export const DELETE_PRESIGNED_URL = gql`
  mutation ($fileName: String!, $type: String) {
    deleteS3Files(fileName: $fileName, type: $type) {
      errorCode
      message
    }
  }
`;
export const GET_DESIGNATION_DETAILS = gql`
  query getDesignationDetails(
    $status: String
    $searchString: String
    $designationIds: [Int]
    $offset: Int
  ) {
    getDesignationDetails(
      status: $status
      searchString: $searchString
      designationIds: $designationIds
      offset: $offset
    ) {
      errorCode
      message
      designationResult {
        Designation_Id
        Designation_Name
        Designation_Code
      }
    }
  }
`;
export const ADD_ERROR_LOG = gql`
  mutation addErrorLog($errorMessage: String!, $url: String!) {
    addErrorLog(errorMessage: $errorMessage, url: $url) {
      message
    }
  }
`;

export const GET_FORM_FIELDS_BY_FORM_ID = gql`
  query getFormFieldsByFormId($form_Id: Int!) {
    getFormFieldsByFormId(form_Id: $form_Id) {
      errorCode
      message
      formFields {
        Field_Id
        Field_Name
        Field_Alias
        Field_Visiblity
        Mandatory_Field
      }
    }
  }
`;

export const RETRIEVE_PAYROLL_GENERAL_SETTINGS = gql`
  query listPayrollGeneralSettings {
    listPayrollGeneralSettings {
      errorCode
      message
      listPayrollGeneralSettingsData {
        Settings_Id
        Slab_Wise_PF
        Slab_Wise_NPS
        Country_Name
        Country_Code
      }
    }
  }
`;
export const RETRIEVE_LOGO_PATH = gql`
  query retrieveLogoPath {
    retrieveLogoPath {
      errorCode
      message
      logoPath
    }
  }
`;
export const TRIGGER_CONTROLLER_FUNCTION = gql`
  mutation triggerControllerFunction($encryptedData: String!, $iv: String!) {
    triggerControllerFunction(encryptedData: $encryptedData, iv: $iv) {
      response
    }
  }
`;

export const RETRIEVE_DROPDOWN_DETAILS = gql`
  query retrieveDropdownDetails(
    $formId: Int
    $key: [String]!
    $conditionDetails: [conditionDetails]
  ) {
    retrieveDropdownDetails(
      formId: $formId
      key: $key
      conditionDetails: $conditionDetails
    ) {
      errorCode
      message
      dropdownDetails
    }
  }
`;
